import http from './http';
// eslint-disable-next-line
import store from '../store';
import gameHeaders from './headers';
import { getApiRoute } from '../../config';

export default {
  payinTicket(data) {
    const { config } = store.getters;
    const headers = {
      ...gameHeaders.getProductHeaders(config),
      ...gameHeaders.getAuthHeader(),
    };
    return http
      .post(`${getApiRoute('baseURL', config)}${process.env.VUE_APP_TICKET_PAYIN}`, data, { headers })
      .then((response) => response.data);
  },
  getRequestUuidCheck(requestUuid) {
    const { config } = store.getters;
    const headers = {
      ...gameHeaders.getProductHeaders(config),
      ...gameHeaders.getAuthHeader(),
    };
    return http
      .get(`${getApiRoute('baseURL', config)}${process.env.VUE_APP_TICKET_REQUEST_CHECK}?requestUuid=${requestUuid}`, { headers })
      .then((response) => response.data);
  },
  getTicketCheck(barcode) {
    const { config } = store.getters;
    const headers = {
      ...gameHeaders.getProductHeaders(config),
      ...gameHeaders.getAuthHeader(),
    };
    return http
      .get(`${getApiRoute('baseURL', config)}${process.env.VUE_APP_TICKET_BARCODE_CHECK}?id=${barcode}`, { headers })
      .then((response) => response.data);
  },
};
