export default {
  SET_CONFIG: 'SET_CONFIG',
  SET_OFFER: 'SET_OFFER',
  SET_USER: 'SET_USER',
  SET_ERROR: 'SET_ERROR',
  SET_TRANSLATIONS: 'SET_TRANSLATIONS',
  GET_EVENTS: 'GET_EVENTS',
  RESET_SELECTED_DATA: 'RESET_SELECTED_DATA',
  SET_SELECTED_EVENT: 'SET_SELECTED_EVENT',
  SET_SELECTED_MARKET: 'SET_SELECTED_MARKET',
  SET_SELECTED_OUTCOME: 'SET_SELECTED_OUTCOME',
  RESET_BETTING_PREVIEW: 'RESET_BETTING_PREVIEW',
  SHOW_EVENTS_PREVIEW: 'SHOW_EVENTS_PREVIEW',
  SHOW_MARKETS_PREVIEW: 'SHOW_MARKETS_PREVIEW',
  SHOW_OUTCOMES_PREVIEW: 'SHOW_OUTCOMES_PREVIEW',
  SET_SELECTED_BET: 'SET_SELECTED_BET',
  SET_EVENT_NAME: 'SET_EVENT_NAME',
  SET_EVENT_ID: 'SET_EVENT_ID',
  SET_EVENT_COUNTDOWN: 'SET_EVENT_COUNTDOWN',
  SEND_GG_MESSAGE: 'SEND_GG_MESSAGE',
  SET_TICKET_PREVIEW_DATA: 'SET_TICKET_PREVIEW_DATA',
  SHOW_TICKET_PREVIEW: 'SHOW_TICKET_PREVIEW',
  SET_STATE_DATA: 'SET_STATE_DATA',
  SET_GAME_STATE_MODE: 'SET_GAME_STATE_MODE',
  UPDATE_BET: 'UPDATE_BET',
  SET_APP_ACTIVE: 'SET_APP_ACTIVE',
  SET_CONNECTION_STATUS: 'SET_CONNECTION_STATUS',
  SET_CONNECTION_LOST_TIME: 'SET_CONNECTION_LOST_TIME',
  RESET_CONNECTION_LOST_TIME: 'RESET_CONNECTION_LOST_TIME',
  SET_HEALTH_CHECKER: 'SET_HEALTH_CHECKER',
  SET_HEALTH_CHECKER_TIME: 'SET_HEALTH_CHECKER_TIME',
};
