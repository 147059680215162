import http from './http';
// eslint-disable-next-line
import gameHeaders from './headers';
import { getApiRoute } from '../../config';

export default {
  async getResults(config) {
    const headers = {
      ...gameHeaders.getProductHeaders(config),
      ...gameHeaders.getAuthHeader(),
    };
    const results = await http.get(`${getApiRoute('baseURL', config)}${process.env.VUE_APP_RESULTS}?channel=retail`, { headers });
    return results.data;
  },
};
