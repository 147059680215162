import Vue from 'vue';
import { merge, isNil } from 'lodash';
// eslint-disable-next-line
import { busService } from '@/utility';
import types from './mutationTypes';

export default {
  [types.SET_APP_ACTIVE](state, payload) {
    state.isAppActive = payload;
  },
  [types.SET_CONFIG](state, payload) {
    state.config = payload;
  },
  [types.SET_OFFER](state, payload) {
    state.offer = payload;
  },
  [types.SET_USER](state, payload) {
    state.user = payload;
  },
  [types.SET_ERROR](state, payload) {
    state.inputError = payload;
  },
  [types.SET_TRANSLATIONS](state, payload) {
    state.translations = merge({}, state.translations, payload);
  },
  [types.GET_EVENTS](state, payload) {
    state.events = payload;
  },
  [types.RESET_SELECTED_DATA](state) {
    state.selectedEvent = {};
    state.selectedMarket = {};
    state.selectedOutcome = {};
  },
  [types.SET_SELECTED_EVENT](state, payload) {
    state.selectedEvent = payload;
  },
  [types.SET_SELECTED_MARKET](state, payload) {
    state.selectedMarket = payload;
  },
  [types.SET_SELECTED_OUTCOME](state, payload) {
    state.selectedOutcome = payload;
  },
  [types.RESET_BETTING_PREVIEW](state) {
    state.showEvents = true;
    state.showMarkets = false;
    state.showOutcomes = false;
  },
  [types.SHOW_EVENTS_PREVIEW](state, payload) {
    state.showEvents = payload;
  },
  [types.SHOW_MARKETS_PREVIEW](state, payload) {
    state.showMarkets = payload;
  },
  [types.SHOW_OUTCOMES_PREVIEW](state, payload) {
    state.showOutcomes = payload;
  },
  [types.SET_SELECTED_BET](state, payload) {
    state.selectedBet = payload;
  },
  [types.SET_EVENT_NAME](state, payload) {
    state.eventName = payload;
  },
  [types.SET_EVENT_ID](state, payload) {
    state.eventIdToday = payload;
  },
  [types.SET_EVENT_COUNTDOWN](state, payload) {
    state.eventCountdown = payload;
  },
  [types.SEND_GG_MESSAGE](state, payload) {
    const { action, data } = payload;
    busService.sendMessage(action, data);
  },
  [types.SET_TICKET_PREVIEW_DATA](state, payload) {
    state.ticketPreviewData = payload;
  },
  [types.SHOW_TICKET_PREVIEW](state, payload) {
    state.ticketPreviewActive = payload;
  },
  [types.SET_STATE_DATA](state, payload) {
    state.gameState = payload;
  },
  [types.SET_GAME_STATE_MODE](state, payload) {
    state.isGameStateMode = payload;
  },
  [types.UPDATE_BET](state, payload) {
    const ticket = state.betslip.tickets[state.betslip.ticketType.type];
    Vue.set(ticket, payload.betIndex, payload.bet);
  },
  [types.SET_CONNECTION_STATUS](state, payload) {
    state.connectionStatus = payload;
  },
  [types.SET_CONNECTION_LOST_TIME](state) {
    if (isNil(state.connectionLostTimestamp)) {
      state.connectionLostTimestamp = new Date().getTime();
      state.connectionLostDuration = 0;
      state.connectionLostInterval = setInterval(() => {
        const oldTime = (state.connectionLostTimestamp || new Date().getTime()) / 1000;
        const newTime = new Date().getTime() / 1000;

        state.connectionLostDuration = newTime - oldTime;
      }, 1000);
    }
  },
  [types.RESET_CONNECTION_LOST_TIME](state) {
    clearInterval(state.connectionLostInterval);
    state.connectionLostInterval = null;
    state.connectionLostTimestamp = null;
    state.connectionLostDuration = 0;
  },
  [types.SET_HEALTH_CHECKER](state, value) {
    console.log(' -- connectionAlive ', value);
    state.connectionAlive = value;
  },
  [types.SET_HEALTH_CHECKER_TIME](state, value) {
    state.healthCheckerTime = value;
  },
};
