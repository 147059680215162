import { BusService } from '@nsftx/games-bus';
import { isEmpty } from 'lodash';
// eslint-disable-next-line
import store from '@/store';
import { bus } from '@/utility';
import { getApiRoute } from '../../config';

let busService;

export default {
  init() {
    const { config, messaging } = store.getters;
    const settings = {
      environment: config.environment,
      platform: config.platform,
      platformName: config.platformName,
      productName: config.productName,
      productId: config.productId,
      tenantId: config.tenantId,
      locale: config.locale,
      applicationName: config.applicationName,
      useCommonEventName: true,
      adapters: [],
      clientMessageProtocol: config.messageProtocol,
    };

    if (messaging === 'pusher') {
      settings.adapters.push({
        name: 'PusherAdapter',
        options: {
          key: getApiRoute('pusherKey', config),
        },
      });
    }
    busService = new BusService(settings);
    busService.addChannel('Product', config.productInstanceUuid);
    busService.start();
    // console.log('---- > Starting NEW Bus service! ');
    busService.sendMessage({
      action: 'Slave.Loaded',
      data: store.getters.settings,
    }, true);
  },
  sendMessage(action, data) {
    console.log(' sendMessage ', action);
    console.log(data);
    return busService?.sendMessage({
      action,
      data,
    }, true);
  },
  // TODO better solution
  // Send ticket data to Seven platform (over Gravity Gateway)
  sendMessageAsync(action, data) {
    console.log(' sendMessageAsync ');
    busService.clientAdapter.handler.emitAsync({
      action,
      data,
    })
      .then((response) => {
        console.log(' emitAsync response ', response);
        const { ticket, code } = response;
        if (ticket.action === 'Add') {
          const codeString = code.toString();
          // Only if status request '202' (accepted) or '499' (unknown error occurred on product) or '-1' (request is timed out)
          // send ticket to product api
          if (codeString === '202' || codeString === '499' || codeString === '-1') {
            bus.$emit('ticketRequestCheck', ticket);
          } else {
            store.dispatch('sendGGNotification', `${store.getters.translation('ticketPayinError')}`);
          }
        } else {
          bus.$emit('ticketBarcodeCheck', response.ticket);
        }
      })
      .catch((error) => {
        console.log(' emitAsync error ', error);
        if (!isEmpty(error)) {
          // In the case of certain errors, such as the Same Location rule,
          // 7Shop displays a notification on its own. Therefore, we must
          // first close the existing notification before displaying our own.
          store.dispatch('closeGGNotification');
          store.dispatch('sendGGNotification', `${store.getters.translation(error.message)}`);
        }
      });
  },
};
