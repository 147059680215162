import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
  {
    path: '/',
    redirect: {
      name: 'Shop',
    },
  },
  {
    path: '/shop',
    name: 'Shop',
    component: () => import(/* webpackChunkName: "shop" */'@/pages/Shop'),
  },
  {
    path: '/web',
    name: 'Web',
    component: () => import(/* webpackChunkName: "shop" */'@/pages/Web'),
  },
  {
    path: '/terminal',
    name: 'Terminal',
    component: () => import(/* webpackChunkName: "shop" */'@/pages/Terminal'),
  },
];

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
