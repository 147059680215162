import http from './http';

const getOffer = async (config) => {
  const { productId = undefined } = config;
  return http.get(`${process.env.VUE_APP_API_GAME_SERVICE}/offer`, {
    headers: { 'X-Nsft-Productid': productId },
  });
};

export default getOffer;
