import {
  last,
  findIndex,
  isNil,
  isEmpty,
} from 'lodash';
// eslint-disable-next-line
import {
  bus,
  busService,
  parser,
  betslipUtility,
} from '@/utility';
import { eventBus } from '@nsftx/games-sdk-js';
import { resultsAPI, getOffer } from '@/api';
import types from './mutationTypes';

let countdownInterval = null;
let healthCheckerTimeout = null;
let defaultHealthCheckerTimeout = null;

const getCoutdownDelay = (startTime) => {
  const eventStartTS = new Date(startTime).getTime();
  const currentTS = new Date().getTime();
  const coutdownDelay = Math.floor((eventStartTS - currentTS) / 1000);

  return coutdownDelay;
};

export default {
  setAppActive({ commit }, payload) {
    commit(types.SET_APP_ACTIVE, payload);
  },
  setConfig({ commit }, payload) {
    commit(types.SET_CONFIG, payload);
  },
  setOffer({ commit }, payload) {
    commit(types.SET_OFFER, payload);
  },
  setUser({ commit }, payload) {
    commit(types.SET_USER, payload);
  },
  setError({ commit }, payload) {
    commit(types.SET_ERROR, payload);
    // commit(types.SHOW_ERROR, !!payload);
  },
  setTranslations({ commit }, payload) {
    commit(types.SET_TRANSLATIONS, payload);
  },
  getEventsOffer({ state, commit }, payload) {
    const eventsOffer = parser.parseEvents(state.offer, payload);
    commit(types.GET_EVENTS, eventsOffer);
  },
  resetSelectedData({ commit }) {
    commit(types.RESET_SELECTED_DATA);
  },
  setSelectedEvent({ commit }, payload) {
    commit(types.SET_SELECTED_EVENT, payload);
  },
  setSelectedMarket({ commit }, payload) {
    commit(types.SET_SELECTED_MARKET, payload);
  },
  setSelectedOutcome({ commit }, payload) {
    commit(types.SET_SELECTED_OUTCOME, payload);
  },
  resetBettingPreview({ commit }, payload) {
    commit(types.RESET_BETTING_PREVIEW, payload);
  },
  showEventsPreview({ commit }, payload) {
    commit(types.SHOW_EVENTS_PREVIEW, payload);
  },
  showMarketsPreview({ commit }, payload) {
    commit(types.SHOW_MARKETS_PREVIEW, payload);
  },
  showOutcomesPreview({ commit }, payload) {
    commit(types.SHOW_OUTCOMES_PREVIEW, payload);
  },
  setSelectedBet({ commit }, payload) {
    commit(types.SET_SELECTED_BET, payload);
  },
  updateBet({ state, commit }, payload) {
    const ticket = state.betslip.tickets[state.betslip.ticketType.type];
    const betIndex = findIndex(ticket, state.selectedBet);
    const newBet = {
      bet: payload,
      betIndex,
    };
    commit(types.UPDATE_BET, newBet);
  },
  checkLockedBets({ dispatch, getters }) {
    const ticketBets = getters['betslip/tickets'];
    const currentDate = new Date().getTime();
    ticketBets.forEach((bet) => {
      const eventStart = new Date(bet.timestamp).getTime();
      if (eventStart < currentDate) {
        dispatch('betslip/updateTicket', {
          bet,
          locked: true,
        }, { root: true });
      }
      if (!isEmpty(getters.selectedBet) && (bet.round === getters.selectedBet.round)) {
        dispatch('resetEditBet');
      }
    });
  },
  sendGGMessage({ commit }, payload) {
    commit(types.SEND_GG_MESSAGE, payload);
  },
  // GG - Gravity Gateway
  sendGGNotification({ dispatch }, payload) {
    const notification = {
      action: 'Dialog.Show',
      data: {
        action: '7S:Dialog.Show',
        message: payload,
        type: 'warning',
        delay: 3000,
      },
    };
    dispatch('sendGGMessage', notification);
  },
  closeGGNotification({ dispatch }) {
    const notification = {
      action: 'Dialog.Close',
      data: {},
    };
    dispatch('sendGGMessage', notification);
  },
  sendAcceptNotification({ getters, dispatch }, payload) {
    const notification = {
      action: 'Dialog.Show',
      data: {
        action: '7S:Dialog.Show',
        message: payload,
        type: 'warning',
        showDefaultAction: {
          key: '1',
          keyCode: [49, 97],
          back: true,
        },
        actions: [
          {
            autoClose: true,
            acceptPayin: true,
            text: `${getters.translation('accept')}`,
            key: '2',
            keyCode: [50, 98],
            noPermission: true,
          },
        ],
        focus: true,
      },
    };
    dispatch('sendGGMessage', notification);
  },
  showTicketPreview({ commit }, payload) {
    commit(types.SET_TICKET_PREVIEW_DATA, payload);
    commit(types.SHOW_TICKET_PREVIEW, !!payload);
  },
  startEventCountdown({ commit, state }) {
    if (countdownInterval) clearInterval(countdownInterval);
    countdownInterval = setInterval(() => {
      let countdown = state.eventCountdown - 1;
      if (countdownInterval && state.eventCountdown <= 0) {
        clearInterval(countdownInterval);
        countdown = 0;
      }

      commit(types.SET_EVENT_COUNTDOWN, countdown);
    }, 1000);
  },
  setStateData({ commit }, payload) {
    commit(types.SET_STATE_DATA, payload);
  },
  /**
   * Sets state.isGameStateMode to true and reverts it back to false after 1 second.
   * We use isGameStateMode to distinguish between stage data
   * received over Pusher and through config.
   */
  setGameStateMode({ commit }) {
    commit(types.SET_GAME_STATE_MODE, true);
    setTimeout(() => {
      commit(types.SET_GAME_STATE_MODE, false);
    }, 1000);
  },
  handleGameState({ dispatch }, payload) {
    console.log(' handleGameState ', payload);
    dispatch('setGameStateMode');
    if (defaultHealthCheckerTimeout) clearInterval(defaultHealthCheckerTimeout);
    const currentEvent = last(payload);
    switch (currentEvent.eventName) {
      case 'showVideos':
        dispatch('handleGameVideos', currentEvent.message);
        break;
      case 'showResults':
        dispatch('handleGameResults', currentEvent.message);
        break;
      case 'startCountdown':
        dispatch('handleGameCountdown', currentEvent.message);
        break;
      default:
    }
  },
  handleGameVideos({ commit, dispatch, getters }, payload) {
    const { data } = payload;
    const numOfBets = getters['betslip/numOfBets'];

    commit(types.SET_EVENT_ID, data.eventIdToday);
    commit(types.SET_EVENT_NAME, 'showVideos');
    dispatch('setHealthCheckerTime', data.totalDuration);
    dispatch('betslip/disablePayinButton', false, { root: true });

    if (numOfBets > 0) {
      dispatch('checkLockedBets');
    }
  },
  handleGameResults({ commit, dispatch, getters }, payload) {
    const { data } = payload;
    const { resultsDuration } = getters;

    commit(types.SET_EVENT_NAME, 'showResults');
    commit(types.SET_EVENT_ID, data.eventIdToday);
    dispatch('setHealthCheckerTime', resultsDuration);
  },
  handleJackpotWon({ commit }) {
    commit(types.SET_EVENT_NAME, 'showJackpotWon');
  },
  handleGameCountdown({ commit, dispatch, getters }, payload) {
    const { isGameStateMode } = getters;
    const { data } = payload;
    const { eventStartTime } = data;

    // If the stage data is received through the config request, we need to manually calculate
    // The seconds until the start of the event. Otherwise (data is received through a real-time socket message),
    // we use the countdown property we are provided with.
    const eventCountdown = isGameStateMode ? getCoutdownDelay(eventStartTime) : data.countdown;
    commit(types.SET_EVENT_NAME, 'startCountdown');
    commit(types.SET_EVENT_ID, data.eventIdToday);
    commit(types.SET_EVENT_COUNTDOWN, eventCountdown);
    dispatch('startEventCountdown');
    dispatch('setHealthCheckerTime', eventCountdown);

    dispatch('betslip/disablePayinButton', false, { root: true });
    // bus.$emit('resetInputs');
  },
  async handleStopBetting({ commit, dispatch }, payload) {
    const { data } = payload;

    commit(types.SET_EVENT_NAME, 'stopBetting');
    await dispatch('fetchAndStoreOffer');
    dispatch('setHealthCheckerTime', data.duration + 1);
    dispatch('betslip/disablePayinButton', true, { root: true });
  },
  async fetchAndStoreOffer({ dispatch, getters: { config } }) {
    const fullOffer = await getOffer(config);

    const offer = fullOffer?.data?.message?.slice(0, config.rules.maxNumEvents.value);
    dispatch('getEventsOffer', offer);
  },
  handleBusMessages({ getters, dispatch }, payload) {
    console.log('handleBusMessages...', payload);
    const { ticketPreviewActive } = getters;
    // if (defaultHealthCheckerTimeout) clearInterval(defaultHealthCheckerTimeout);
    const { eventName } = payload;
    switch (eventName) {
      // Pusher game events
      case 'startCountdown':
        dispatch('handleGameCountdown', payload);
        break;
      case 'showVideos':
        dispatch('handleGameVideos', payload);
        break;
      case 'showResults':
        dispatch('handleGameResults', payload);
        break;
      case 'stopBetting':
        dispatch('handleStopBetting', payload);
        break;
      // Gravity Gateway events
      case 'UI.Show':
        dispatch('showTicketPreview', null);
        /* setTimeout(() => {
          bus.$emit('focusFirstInput');
        }, 10); */
        break;
      case 'Slave.Shown':
        dispatch('showTicketPreview', null);
        setTimeout(() => {
          bus.$emit('focusFirstInput');
        }, 10);
        break;
      case 'Master.Event':
        if (payload.event === 'keydown') {
          if (ticketPreviewActive) {
            bus.$emit('ticketPreviewListener', payload);
          } else {
            dispatch('handleShortcuts', payload);
          }
        }
        break;
      case 'Widget.Event':
        if (payload.data.event === 'click' || payload.data.shortcut) {
          dispatch('handleShortcuts', {
            key: payload.data.shortcut,
          });
        }
        // If press accept and proceed button insted of payin
        if (payload.data.action?.acceptPayin) {
          const ticketBets = getters['betslip/tickets'];
          dispatch('betslip/removeInvalidBets').then(() => {
            dispatch('betslip/disablePayinButton', false, { root: true });
            if (ticketBets.length > 0) {
              // dispatch('ticketPayin');
              eventBus.$emit('focusBetslipFooterInput');
            } else {
              dispatch('resetAll');
            }
          });
        }
        if (payload.data.action?.back) {
          dispatch('betslip/disablePayinButton', false, { root: true });
          bus.$emit('focusFirstInput');
        }
        break;
      case 'Betslip.Blocked':
        // Check if we block betslip and show message betslip app (games-sdk)
        dispatch('betslip/disablePayinButton', true, { root: true });
        dispatch('betslip/setBetslipBlockers', {
          blockers: payload.data.blockers,
          type: 'add',
        });
        break;
      case 'Betslip.Unblocked':
        dispatch('betslip/disablePayinButton', false, { root: true });
        dispatch('betslip/setBetslipBlockers', {
          blockers: payload.data.blockers,
          type: 'remove',
        });
        // bus.$emit('focusFirstInput');
        break;
      // Checked the ticket and forwards ticket data to ticket preview
      case 'Tickets.Checked': {
        const dataSettings = {
          action: 'Widget.UpdateSettings',
          data: {
            gamesHelp: {
              show: false,
            },
            calculator: {
              show: false,
            },
            ticketSession: {
              show: false,
            },
            ticketList: {
              show: false,
            },
          },
        };
        dispatch('sendGGMessage', dataSettings);
        dispatch('showTicketPreview', payload.data);
        break;
      }
      case 'Tickets.PayingSentSuccess': {
        dispatch('resetEditBet');
        dispatch('betslip/clearBetslip', true, { root: true });
        dispatch('betslip/disablePayinButton', false, { root: true });
        break;
      }
      case 'Tickets.PayingSuccess': {
        // const { requestUuid } = payload.data.ticket;
        // ticketChecker.setChecker(requestUuid);
        // bus.$emit('ticketRequestCheck', requestUuid); // call product ticket request check
        break;
      }
      case 'Tickets.PayingFailed':
        dispatch('betslip/disablePayinButton', false, { root: true });
        break;
      case 'Tickets.Payout': {
        const { ticket } = payload.data;
        const ticketData = {
          action: 'Tickets.Payout',
          data: { ticket },
        };
        console.log(' auto payout sendMessageAsync ', ticketData);
        busService.sendMessageAsync(ticketData.action, ticketData.data);
      }
        break;
      // NOT DEPRECATED for old payin flow
      case 'Tickets.PrePrint': {
        if (!payload.data.ticket.isCopy) {
          bus.$emit('ticketPrint', payload.data.ticket);
        }
        break;
      }
      case 'Tickets.PrintCopy': {
        bus.$emit('ticketPrint', payload.data.ticket);
        break;
      }
      case 'User.AuthorizationChanged':
        dispatch('setUser', payload.data);
        break;
      // no default
    }
  },
  handleShortcuts({ dispatch }, payload) {
    // console.log(' handleShortcuts ', payload);
    switch (payload.key) {
      case '+':
        // New ticket
        bus.$emit('resetInputs');
        break;
      case '/':
        // Reset all
        dispatch('resetAll');
        break;
      case '*':
        dispatch('getResults');
        break;
      default:
    }
  },
  async getResults({ getters }) {
    // console.log(' getResults ');
    try {
      const { config } = getters;
      const results = await resultsAPI.getResults(config);
      busService.sendMessage('Peripherals.Print', {
        layoutName: 'resultsVirtualSoccer',
        productId: config.productName,
        data: results,
      });
    } catch (error) {
      console.log(error);
    }
  },
  ticketPayin({ getters, dispatch }) {
    const validatePayin = betslipUtility.validateBetslip();
    console.log(' akcija ticketPayin ', validatePayin);
    if (validatePayin.isValid) {
      dispatch('closeGGNotification');
      const ticket = betslipUtility.createTicket();
      if (!getters.directPayin) {
        const ticketPayinData = {
          action: 'Tickets.Pay',
          data: {
            validateTicket: false,
            skipBetslipReset: true,
            ticketFormatted: ticket,
            ticket,
          },
        };
        busService.sendMessageAsync(ticketPayinData.action, ticketPayinData.data);
      } else {
        dispatch('betslip/disablePayinButton', true, { root: true });
        bus.$emit('ticketPayin');
      }
    } else if (validatePayin.acceptPayin) {
      dispatch('sendAcceptNotification', validatePayin.message);
    } else {
      dispatch('sendGGNotification', validatePayin.message);
    }
  },
  resetAll({ dispatch }) {
    bus.$emit('resetInputs');
    dispatch('betslip/clearBetslip', true, { root: true });
    dispatch('betslip/disablePayinButton', false, { root: true });
  },
  resetEditBet({ dispatch }) {
    bus.$emit('resetInputs');
    dispatch('setSelectedBet', null);
    dispatch('betslip/setEditBet', null, { root: true });
  },
  updateOnlineStatus({ commit, state }) {
    const isOnline = navigator.onLine;
    if (isOnline) {
      commit(types.RESET_CONNECTION_LOST_TIME);
    } else if (isNil(state.connectionLostTimestamp)) {
      commit(types.SET_CONNECTION_LOST_TIME);
    }
    commit(types.SET_ONLINE_STATUS, isOnline);
  },
  setConnectionStatus({ commit }, payload) {
    commit(types.SET_CONNECTION_STATUS, payload);
  },
  setHealthChecker({ commit, getters }) {
    console.log(' 2 setHealthChecker ', getters.healthCheckerTime);
    commit(types.SET_HEALTH_CHECKER, true);

    clearTimeout(healthCheckerTimeout);
    healthCheckerTimeout = setTimeout(() => {
      console.log(' 3 setHealthChecker setTimeout ', getters.healthCheckerTime);
      if (getters.connectionStatus) {
        console.log(' 4 setHealthChecker connectionStatus ', this.getters.connectionStatus);
        commit(types.SET_HEALTH_CHECKER, false);
      }
    }, getters.healthCheckerTime * 1000);
  },
  setHealthCheckerTime({ commit, dispatch }, payload) {
    console.log(' 1 setHealthChecker TIME ', payload);
    if (!isNil(payload) && payload >= 0) {
      commit(types.SET_HEALTH_CHECKER_TIME, payload + 1);
      dispatch('setHealthChecker');
    }
  },
  setDefaultHealthChecker({ commit, getters }) {
    console.log(' ===== 1 setDefaultHealthChecker ', getters.defaultHealthCheckerTime);
    if (defaultHealthCheckerTimeout) clearInterval(defaultHealthCheckerTimeout);
    defaultHealthCheckerTimeout = setInterval(() => {
      console.log(' ===== 2 setInterval ', getters.defaultHealthCheckerTime);
      if (!getters.isConnectionAlive && getters.connectionStatus) {
        console.log(' ====== !! isOnline  eventBus emit$');
        commit(types.SET_HEALTH_CHECKER, false);
        bus.$emit('getConfigState');
      }
    }, getters.defaultHealthCheckerTime * 1000);
  },
};
