<template>
  <transition name="loader-wrapper">
    <div class="loader">
      <div class="loader-content center-xy">
        <div class="loader-spinner">
        </div>
      </div>
    </div>
  </transition>
</template>
<script>

export default {
  name: 'Loader',
};
</script>

<style lang="scss">
.loader {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  background-color: $black-opacity-60;
  .loader-spinner {
    width: 2.5rem;
    height: 2.5rem;
    border: solid 2px $white-opacity-20;
    border-left-color: $white;
    border-radius: 50%;
    animation: spinner 1s infinite linear;
  }
}
@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
