export default {
  connectionStatus: true,
  connectionLostTimestamp: null,
  connectionLostInterval: null,
  connectionLostDuration: 0,
  connectionOfflineLimit: 5,
  connectionAlive: false,
  isOnline: true,
  config: {},
  offer: {},
  user: {},
  events: [],
  selectedEvent: {},
  selectedMarket: {},
  selectedOutcome: {},
  showEvents: true,
  showMarkets: false,
  showOutcomes: false,
  selectedBet: null,
  gameState: undefined,
  isGameStateMode: true,
  isAppLoaded: false,
  eventName: 'startCountdown',
  eventIdToday: 0,
  eventCountdown: 0,
  eventInProgress: true,
  bettingDisabled: false,
  inputError: null,
  isAppActive: false,
  // false: old flow for payin, use GG event 'Tickets.Pay' on 7Shop;
  // true: new flow for payin, send ticket directly to product backend
  directPayin: false,
  ticketType: {
    id: 2,
    name: 'combo',
  },
  ticketPreviewData: null,
  ticketPreviewActive: false,
  ticketStatuses: {
    neutral: ['open', 'pending'],
    positive: ['won', 'paidout', 'jackpot'],
    negative: ['lost', 'closed', 'expired', 'rejected'],
  },
  statusIcons: {
    neutral: 'icon-clock',
    positive: 'icon-check-a',
    negative: 'icon-close',
  },
  // Ticket template layout name from CMS
  printLayoutType: {
    add: 'ticketVirtualSoccer',
    payout: 'ticketVirtualSoccerPayout',
    cancel: 'ticketVirtualSoccerCancel',
  },
  messaging: 'pusher',
  settings: {
    // icon: 'n-i-virtual-soccer',
    ticketConfig: {
      // color: '#819659',
      availableTicketActions: {
        closed: [],
        // * 7Shop Internals work as follows:
        // * only if any supported action is provided
        // * for a status, the default 7Shop statuses
        // * (Preview, PrintCopy and Rebet if the game supports the rebet option)
        // * will be displayed.
        lost: [
          {
            action: 'checkStatus',
            conditions: [],
          },
        ],
        rejected: [],
        in_play: [],
        open: [{ action: 'cancel', conditions: [] }],
        pending: [
          { action: 'cancel', conditions: [] },
          {
            action: 'checkStatus',
            conditions: [],
          },
        ],
        unknown: [
          {
            action: 'checkStatus',
            conditions: [],
          },
        ],
        failed: [
          {
            action: 'checkStatus',
            conditions: [],
          },
        ],
        won: [{ action: 'payout', conditions: [] }],
        won_overdue: [
          // EXPIRED
          { action: 'payout', conditions: [] },
        ],
        jackpot: [{ action: 'payout', conditions: [] }],
        paidout: [{ action: 'reprintConfirmation', conditions: [] }],
      },
      validateTicket: false,
      useTicketLocalStorage: true,
      doTicketStatusCheck: true,
      pendingTicketCheckInterval: 10000,
      pendingTicketRejectPeriod: 30000,
    },
    shortcuts: {},
  },
  productTicketChecker: true,
  stopBettingDuration: 5,
  resultsDuration: 21,
  healthCheckerTime: 6,
  defaultHealthCheckerTime: 30,
  translations: {},
};
