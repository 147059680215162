export default {
  common: {},
  virtualsoccerdev: {
    development: {
      baseURL: 'https://games-vsts.dev.de-3.nsoft.cloud',
      pusherKey: '9026609befc0809e6234',
    },
    staging: {
      baseURL: 'https://games-vsts.dev.de-3.nsoft.cloud',
      pusherKey: '9026609befc0809e6234',
    },
    production: {
      baseURL: 'https://games-vsts.de-3.nsoft.cloud',
      pusherKey: '9026609befc0809e6234',
    },
  },
  virtualsoccer: {
    development: {
      baseURL: 'https://games-vsts.dev.de-3.nsoft.cloud',
      pusherKey: 'c197ae95f575bc466e26',
    },
    staging: {
      baseURL: 'https://games-vsts.staging.de-3.nsoft.cloud',
      pusherKey: 'c197ae95f575bc466e26',
    },
    production: {
      baseURL: 'https://games-vsts.de-3.nsoft.cloud',
      pusherKey: '112cd2f19bc6b016084d',
    },
  },
};
