const doesOutcomeTypeHaveTranslationKey = (marketId) => {
  if (!marketId) {
    console.warn('No market id has been passed');
    return false;
  }

  const translatableOutcomesMarketIds = [4, 5, 6, 7];

  return translatableOutcomesMarketIds.includes(marketId);
};

const getOutcomeTypeTranslationKey = (marketId, outcomeType) => {
  if (!marketId) {
    console.warn('No market id has been passed');
    return outcomeType;
  }

  if (!outcomeType) {
    console.warn('No outcome type has been passed');
    return outcomeType;
  }

  if (!doesOutcomeTypeHaveTranslationKey(marketId)) return outcomeType;

  return parseInt(outcomeType, 10) === 1 ? 'under' : 'over';
};

export { doesOutcomeTypeHaveTranslationKey, getOutcomeTypeTranslationKey };
