import { isEmpty } from 'lodash';

export default {
  connectionStatus: (state) => state.connectionStatus,
  isLongerOffline: (state) => state.connectionLostDuration > state.connectionOfflineLimit,
  connectionLostDuration: (state) => state.connectionLostDuration,
  isOnline: (state) => state.isOnline,
  config: (state) => state.config,
  /**
   * This is in reality, the offerSpecification from the config request
   * @param {} state
   * @returns
   */
  offer: (state) => state.offer,
  allMarketShorcuts: (state, getters) => getters.offer.map(({ shortcut }) => shortcut),
  getAllOutcomeShorcuts: (state, getters) => (marketId) => getters.offer
    .find((market) => market.marketId === marketId)
    ?.outcomes.map(({ shortcut }) => shortcut),
  user: (state) => state.user,
  events: (state) => state.events,
  selectedEvent: (state) => state.selectedEvent,
  selectedMarket: (state) => state.selectedMarket,
  selectedOutcome: (state) => state.selectedOutcome,
  selectedEventName: (state, getters) => {
    const { selectedEvent } = getters;
    let eventName = null;
    if (!isEmpty(selectedEvent) && selectedEvent.competitors) {
      eventName = `${selectedEvent.competitors[0].name} - ${selectedEvent.competitors[1].name}`;
    }
    return eventName;
  },
  selectedMarketName: (state, getters, rootState, rootGetters) => {
    const { selectedMarket } = getters;
    let marketName = null;
    let marketDisplayName = null;
    if (!isEmpty(selectedMarket)) {
      marketName = rootGetters.translation(selectedMarket[0].marketType);
      marketDisplayName = selectedMarket[0].limitValue
        ? `${marketName} -${selectedMarket[0].limitValue}+`
        : `${marketName}`;
    }
    return marketDisplayName;
  },
  selectedEventInProgress: (state, getters) => {
    const { selectedEvent } = getters;
    if (!isEmpty(selectedEvent)) {
      return getters.eventInProgress && selectedEvent.eventIdToday === getters.eventIdToday;
    }
    return false;
  },
  rules: (state) => state.config.rules,
  showEvents: (state) => state.showEvents,
  showMarkets: (state) => state.showMarkets,
  showOutcomes: (state) => state.showOutcomes,
  selectedBet: (state) => state.selectedBet,
  gameState: (state) => state.gameState,
  isGameStateMode: (state) => state.isGameStateMode,
  isAppLoaded: (getters) => !isEmpty(getters.config) && !isEmpty(getters.offer),
  eventId: (state) => state.eventId,
  eventName: (state) => state.eventName,
  eventIdToday: (state) => state.eventIdToday,
  eventCountdown: (state) => state.eventCountdown,
  eventInProgress: (state, getters) => {
    const { eventName } = getters;
    const events = ['startCountdown', 'stopBetting'];
    return eventName && events.indexOf(eventName) < 0;
  },
  bettingDisabled: (state) => state.eventName && state.eventName === 'stopBetting',
  inputError: (state) => state.inputError,
  ticketPreviewData: (state) => state.ticketPreviewData,
  ticketPreviewActive: (state) => state.ticketPreviewActive,
  translation: (state) => (key) => state.translations[key] || key,
  isAppActive: (state) => state.isAppActive,
  directPayin: (state) => state.directPayin,
  printLayoutType: (state) => state.printLayoutType,
  messaging: (state) => state.messaging,
  settings: (state, getters) => {
    const channelSettings = {
      ticketConfig: state.settings.ticketConfig,
      shortcuts: {
        '+': {
          action: 'ticket',
          active: true,
          name: getters.translations.newTicket || 'newTicket',
          shortcut: '+',
        },
        '*': {
          action: 'results',
          active: true,
          name: getters.translations.results || 'results',
          shortcut: '*',
        },
        '/': {
          action: 'reset_ticket',
          active: true,
          name: getters.translations.resetTicket || 'resetTicket',
          shortcut: '/',
        },
      },
    };
    return channelSettings;
  },
  productTicketChecker: (state) => state.productTicketChecker,
  stopBettingDuration: (state) => state.stopBettingDuration,
  resultsDuration: (state) => state.resultsDuration,
  healthCheckerTime: (state) => state.healthCheckerTime,
  defaultHealthCheckerTime: (state) => state.defaultHealthCheckerTime,
  isConnectionAlive: (state) => state.connectionAlive,
};
