import axios from 'axios';
import axiosRetry from 'axios-retry';

/*
Change baseURL to suit your project needs.
*/
const http = axios.create({
  baseURL: process.env.VUE_APP_API_BASEURL,
  timeout: 5000,
});
axiosRetry(http, {
  retries: 3,
  retryCondition: () => true, // retry no matter what
  retryDelay: (retryCount) => {
    // on every retry, we exponentially increase the delay
    const delay = 2 ** retryCount * 1000;
    console.log(' retry delay ', delay);
    return delay;
  },
  shouldResetTimeout: true,
});

export default http;
