<template>
  <div class="template">
    <Loader v-if="!isAppLoaded">
    </Loader>
    <transition v-else>
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Loader from '@/components/Loader';

export default {
  name: 'TemplateDefault',
  components: {
    Loader,
  },
  computed: {
    ...mapGetters(['isAppLoaded']),
  },
};
</script>

<style scoped lang="scss">
.template {
  background: grey;
  height: 100%;
}
</style>
