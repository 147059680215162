// import Vue from 'vue';
// eslint-disable-next-line
import { bus } from '@/utility';

let checker = null;

const clearInterval = () => {
  console.log(' CLEAR INTERVAL');
  window.clearInterval(checker);
  checker = null;
};

const checkTicket = (ticket, config) => {
  console.log(' CHECK TICKET ');
  let attempts = 0;
  // const { requestUuid } = ticket;
  // Vue.set(ticket, 'requestUuid', false);
  bus.$emit('ticketRequestCheck', ticket);
  checker = window.setInterval(() => {
    attempts += 1;
    bus.$emit('ticketRequestCheck', ticket);
    if (attempts === config.repetitions) {
      // clearInterval();
      // Vue.set(ticket, 'failed', true);
      bus.$emit('ticketUpdateFailed', ticket);
    }
  }, config.delay);
};

export default {
  setChecker(ticket) {
    checkTicket(ticket, {
      delay: 6000,
      repetitions: 3,
    });
  },
  clearChecker() {
    clearInterval();
  },
};
