// eslint-disable-next-line
import { localStorage } from '@/utility';

export default {
  getProductHeaders(config) {
    return {
      'X-Nsft-Seven-Tenant-Uuid': config.tenantId,
    };
  },
  getAuthHeader() {
    return {
      Authorization: `Bearer ${localStorage.getItem('auth')}`,
    };
  },
};
