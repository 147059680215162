/*
Use this file to parse and set your configurations.
You can use process.env to get enviroment variables set in env files.
*/
import { toLower, isNil } from 'lodash';
import apiRoutes from './routes';

const env = process.env.VUE_APP_ENVIRONMENT || process.env.NODE_ENV;
const routes = Object.assign(apiRoutes.common, apiRoutes[env]);

const getApiRoute = (routeName, config) => {
  const productName = toLower(config.productName);
  const { environment } = config;
  if (!isNil(productName) && !isNil(apiRoutes[productName])) {
    return apiRoutes[productName][environment][routeName];
  }
  return routes[routeName];
};
export {
  env,
  routes,
  getApiRoute,
};
