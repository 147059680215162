import * as Sentry from '@sentry/browser';

export default {
  start(Vue) {
    console.log(' sentry');
    Sentry.init({
      Vue,
      dsn: 'https://16fe28a4e1d74de88641c423e32866c9@o73276.ingest.sentry.io/6274895',
      autoSessionTracking: true,
      environment: process.env.VUE_APP_ENVIRONMENT,
      logErrors: true,
      ignoreErrors: [],
    });
  },
};
